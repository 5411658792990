/* Simple SVG Christmas Lights Animation */

#Christmas_Lights {
  height: 100%;
  margin: 0;
  position: absolute;
  width: 100%;
  z-index: 1000
}

.light_cord {
  fill: none;
  stroke: #b9b9b9;
}

.light_fixture {
  fill: #b9b9b9;
}

.red_bulb {
  fill: #CA2128;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
}

.green_bulb {
  fill: #2D754A;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
}

.blue_bulb {
  fill: #2C3EA8;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
}

.white_bulb {
  fill: #F5EED7;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
}

.gold_bulb {
  fill: #ffcc5e;
  stroke: #6D6D6D;
  stroke-miterlimit: 10;
}


/* Simply changing the opacity of the SVG fill to create the glow effect for the bulbs */

.bulb {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: glow;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
  animation-name: glow;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@-webkit-keyframes glow {
  0% {
    fill-opacity: 0.25;
  }
  100% {
    fill-opacity: 1
  }
}

@keyframes glow {
  0% {
    fill-opacity: 0.25;
  }
  100% {
    fill-opacity: 1.0
  }
}


/* Made a set of ten animation delays to create a more random effect of lights blinking.  Simplest way without jumping through SVG hoops. */

.blink-1 {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.blink-2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.blink-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.blink-4 {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

.blink-5 {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.blink-6 {
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}

.blink-7 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.blink-8 {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

.blink-9 {
  -webkit-animation-delay: 0.95s;
  animation-delay: 0.95s;
}

.blink-10 {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}